import Vue from 'vue';
import '@/assets/scss/vue-tables.scss';
import {get, postBuffer} from '@/services/api';
import {Pagination} from '@/utils/pagination';
import {decrypt} from '@/helpers/encrypt';
import {mapGetters, mapMutations, mapState} from 'vuex';
import CreditsView from '@/components/common/CreditsView';
import CurrencyPriceView from '@/components/common/CurrencyPriceView';
import {CREDITS_COLORS} from "@/constants";

export default Vue.extend({
  name: 'CreditAdminHistory',
  components: {
    CreditsView,
    CurrencyPriceView
  },
  computed: {
    ...mapState('buySale', ['information']),
    ...mapGetters('geo', ['currencyCreditSymbol', 'creditsToLocalCredits', 'currencyToUser']),
    ...mapState('geo', ['showInLocal'])
  },
  data: () => ({
    columns: [
      {
        key: 'description',
        label: 'Description',
        tdClass: 'withColumn',
        sortable: true
      },
      {
        key: 'model',
        label: 'Category',
        tdClass: 'withColumn',
        sortable: true
      },
      {
        key: 'type',
        label: 'Type',
        tdClass: 'withColumn',
        sortable: true
      },
      {
        key: 'movement',
        label: 'Movement',
        tdClass: 'withColumn',
        sortable: true
      },
      {
        key: 'VIN',
        label: 'VIN',
        tdClass: 'withColumn',
        sortable: true
      },
      {
        key: 'object.vehicle.brand',
        label: 'Vehicle',
        tdClass: 'withColumn',
        sortable: true
      },
      {
        key: 'object.descriptionObject.description.client.name',
        label: 'Customer',
        tdClass: 'withColumn',
        sortable: true
      },
      {
        key: 'object.type',
        label: 'Type',
        tdClass: 'withColumn',
        sortable: true
      },
      {
        key: 'object.vehicle.engine_family',
        label: 'ECU family',
        tdClass: 'withColumn',
        sortable: true
      },
      {
        key: 'object.stage',
        label: 'Stage',
        tdClass: 'withColumn',
        sortable: true
      },
      {
        key: 'object.vehicle.dsg',
        label: 'TCU',
        tdClass: 'withColumn',
        sortable: true
      },
      {
        key: 'amount',
        label: 'Received',
        tdClass: 'withColumn text-right',
        sortable: true
      },
      {
        key: 'used',
        label: 'Used',
        tdClass: 'withColumn text-right',
        sortable: true
      },
      {
        key: 'remaining',
        label: 'Remaining',
        tdClass: 'withColumn text-right',
        sortable: true
      },
      {
        key: 'createdAt',
        label: 'Date',
        tdClass: 'withColumn',
        sortable: true
      }
    ],
    tableData: [],
    userBalance: 0,
    pagination: new Pagination(),
    users: [],
    filter: {
      userId: null,
      username: null,
      allBalance: true,
      countryId: null,
      query: null
    },
    totalBought: 0,
    totalUsed: 0,
    totalTransferred: 0,
    totalRemaining: 0,
    sessionUserId: null
  }),
  mounted() {
    let id = localStorage.getItem('admin-id');
    this.sessionUserId = decrypt(id);
    this.getData();
    this.loadClients();
  },
  watch: {
    'filter.userId'() {
      if (this.filter.userId) {
        this.filter.username = this.users.find(x => x.id === this.filter.userId).fullName
        this.filter.countryId = this.users.find(x => x.id === this.filter.userId).countryId
      } else {
        this.filter.username = null;
        this.filter.countryId = null;
      }
      this.pagination.currentPage = 1
      this.getData();
    },
  },
  methods: {
    ...mapMutations(['loaderManager']),
    async getData() {
      let id = localStorage.getItem('admin-id');
      id = decrypt(id);
      this.loaderManager(true);
      get(`credit-movement?page=${this.pagination.currentPage}&limit=${this.pagination.perPage}&id=${this.filter.userId}&query=${encodeURIComponent(this.filter.query)}`,
        null, true)
        .then(({data}) => {
          this.totalBought = data.bought;
          this.totalUsed = data.used;
          this.totalTransferred = data.transferred;
          this.totalRemaining = Number(this.totalBought) + Number(this.totalUsed);
          this.tableData = data.data.map(row => {
            let type = '';
            if (row.type !== null) {
              type = this.getType(row.type, row.userId, id)
            }
            if (row.amount !== null) {
              if (row.amount > 0) {
                row.used = 0
              } else {
                row.used = row.amount
                row.amount = 0;
              }
            }
            return {
              ...row,
              type,
              object: row.softwareOrder ? {
                ...row.softwareOrder,
                vehicle: (row.softwareOrder !== null) ? JSON.parse(row.softwareOrder.vehicle) : '',
              } : null,
            };
          });
          this.pagination.total = data.count;
          this.loaderManager(false);
        });
    },
    change(e) {
      this.pagination.currentPage = e;
      this.getData();
    },
    loadClients() {
      get('user/distributor,dealer,subdealer', null, true)
        .then(({data}) => {
          this.users = data;
        });
    },
    getColor(amount) {
      return (Number(amount) > 0) ? CREDITS_COLORS.positive : (Number(amount) < 0) ? CREDITS_COLORS.negative : CREDITS_COLORS.zero
    },
    getType(data, userId, id) {
      const parse = JSON.parse(data);
      if (this.information.rol.includes('administrator') || this.information.rol.includes('support')) {
        return parse.admin;
      } else if (this.information.rol.includes('distributor')) {
        return (Number(userId) === Number(id)) ? parse.distributor : parse.dealer;
      } else if (this.information.rol.includes('dealer') || this.information.rol.includes('subdealer')) {
        return parse.dealer;
      }
    },
    async downloadPdf() {
      this.loaderManager(true);
      postBuffer(`credit-movement/export-overview-pdf`,
        {
          allBalance: false,
          id: this.sessionUserId
        },
        true)
        .then(async ({data}) => {
          const fileName = `credit-overview- ${new Date().getTime()}.pdf`;
          const file2 = new File([data], fileName, {type: 'application/pdf'});
          saveAs(file2);
          this.loaderManager(false);
          location.reload()
        })
        .catch(e => {
          this.loaderManager(false);
        });
    },
    keySearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getData()
      }, 1000);
    },
  },
});

